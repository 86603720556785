<template>
  <v-row class="pa-0">
    <v-col :cols="rango_sel == 4 ? 5 : 12">
      <v-autocomplete
        v-model="rango_sel"
        tabindex="1"
        :items="rangos.filter(ra => !ra.promax || (ra.promax && promax))"
        hide-details
        outlined
        dense
      ></v-autocomplete>
    </v-col>
    <v-col v-if="rango_sel == 4" cols="7">
      <v-menu
        v-model="menu"
        transition="scale-transition"
        min-width="auto"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates_format"
            :title="dates_format"
            hide-details
            clearable
            outlined
            readonly
            filled
            dense
          >
            <template v-slot:append-outer>
              <v-icon
                v-bind="attrs"
                v-on="on"
                tabindex="1"
                color="info"
                title="Seleccionar rango"
              >
                fas fa-calendar-week
              </v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          color="primary"
          no-title
          range
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
/*
  Devuelve las fechas en formato YYYY-MM-DD
 */
import moment from 'moment'

export default {
  data () {
    return {
      menu: false,
      dates_format: '',
      rango_sel: this.rango_def,
      rangos: [
        { value: 1, text: 'Hoy' },
        { value: 5, text: 'Última semana' },
        { value: 2, text: 'Último mes' },
        { value: 3, text: 'Últimos 6 meses' },
        { value: 6, text: 'Último año', promax: true },
        { value: 4, text: 'Personalizado' },
      ],
    }
  },
  created () {
    this.set_dates(this.rango_def)
  },
  props: {
    /**
     *  En el v-model debe enviarse un array con dos valores null => [ null, null ]
     *  correspondientes a la fecha desde y fecha hasta
     * 
     *  El componente ya toma al valor de la fehcha en base al
     *  rango por defecto
     */
    value: Array,
    // rango por defecto: Último mes
    rango_def: {
      type: Number,
      default: 2,
    },
    // este prop sirve cuando se limpia el filtro desde otra vista, para que se actualice el valor al rango por defecto
    // debe enviase sincronizaco con .sync
    limpiar: {
      type: Boolean,
      default: false,
    },
    promax: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dates: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    limpiar_filtro: {
      get () {
        return this.limpiar
      },
      set (value) {
        this.$emit('update:limpiar', value)
      }
    },
  },
  watch: {
    rango_sel (val) {
      this.set_dates(val)
      this.$emit('changeSize', val == 4)
      this.$emit('selectOption', val)
    },
    limpiar_filtro (val) {
      if (val) {
        if (this.rango_sel === this.rango_def) {
          this.set_dates(this.rango_def)
        } else {
          this.rango_sel = this.rango_def
        }
        this.limpiar_filtro = false
      }
    },
    dates () {
      if (this.dates[0] && this.dates[1]) {
        this.dates_format = `${this.format_date(this.dates[0])}-${this.format_date(this.dates[1])}`
      } else if (this.dates[0]) {
        this.dates_format = `${this.format_date(this.dates[0])}`
      } else {
        this.dates_format = ''
      }
    },
    dates_format (val) {
      if (!val) {
        this.dates[0] = ''
        this.dates[1] = ''
      }
    }
  },
  methods: {
    set_dates (val) {
      switch (val) {
        case 1:
          this.dates[0] = moment(new Date()).format("YYYY-MM-DD")
          this.dates[1] = ''
          break
        case 2:
          this.dates[0] = moment(new Date()).subtract(1, 'months').format("YYYY-MM-DD")
          this.dates[1] = ''
          break
        case 3:
          this.dates[0] = moment(new Date()).subtract(6, 'months').format("YYYY-MM-DD")
          this.dates[1] = ''
          break
        case 4:
          this.dates_format = ''
          this.dates[0] = ''
          this.dates[1] = ''
          break
        case 5:
          this.dates[0] = moment(new Date()).subtract(1, 'week').format('YYYY-MM-DD')
          this.dates[1] = ''
          break
        case 6:
          this.dates[0] = moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD')
          this.dates[1] = ''
          break
      }
    },
    format_date (date) {
      if (!date) return ''
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
  }
}
</script>