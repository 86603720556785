<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <!-- Filtros -->
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo Filtros -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <!-- Sucursal, Local y Vendedor -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-0">
                    Sucursal
                    <v-autocomplete
                      v-model="selectedSucursal"
                      item-text="nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      return-object
                      @change="getLocales()"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-0">
                    Local
                    <v-autocomplete
                      v-model="selectedLocal"
                      item-text="nombre"
                      :items="locales"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="py-0">
                    Vend. Código
                    <v-text-field
                      v-model="codigo"
                      type="number"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-0">
                    Vend. Nombre
                    <v-text-field
                      v-model="nombre"
                      type="text"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Periodo y Botones Filtro -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="2" md="2" class="py-1">
                    Periodo
                    <PeriodoPicker
                      v-model="periodo"
                      :label="false"
                      :hideDetails="true"
                      @change="setHeaders()"
                    />
                  </v-col>
                  <v-col cols="12" sm="10" md="10" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Listado de Objetivos -->
        <v-data-table
          class="cebra elevation-2 mt-2"
          :headers="ver_periodo == 0 ? headers : headerss"
          :items="objetivos"
          :loading="load"
          dense
          :search="search"
          sort-by="periodo"
          :sort-desc="true"
        >
          <!-- Buscador en el datatable -->
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-2" no-gutters>
              <v-col cols="6" sm="9" class="d-flex justify-start align-center">
                <DownloadDataTable
                  name="Objetivos alcanzados"
                  :data="objetivos"
                  :headers="{
                    'Periodo': 'periodo',
                    'Local': 'punto_venta',
                    'Vendedor': 'vendedor',
                    '% Dedic. Horaria': 'dedicacion_horaria',
                    'Obj. Total. Vta.': 'obj_solicitado_total_vta',
                    'Vta. Alcanzada': 'objetivo_total_alcanzado',
                    '% Proy. Vta.': 'porc_proy_vta',
                    'Obj. Acc.': 'obj_solicitado_acc_piso',
                    'Acc. Alcanzado': 'obj_acc_alcanzado',
                    '% Proy. Acc.': 'porc_proy_acc',
                    'Incentivo': 'incentivo',
                    'Penaliz.': 'penalizaciones',
                    'Total Gift': 'total_gift',
                    'Entregado Gift': 'monto_entregado_gift',
                    'Diferencia Gift': 'dif_gift'}"
                />
              </v-col>
              <v-col cols="6" sm="3">
                <SearchDataTable
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
          <template
            v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <!-- Items -->
          <template v-slot:[`item.periodo`]="{ item }">
            {{ moment(moment(item.periodo).format('DD/MM/YYYY').toString().substring(3, moment(item.periodo).format('DD/MM/YYYY').toString().length), 'MM/YYYY').format('MM/YYYY') }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-tooltip bottom color="primary" right>
              <template v-slot:activator="{ on }">
                <v-btn small icon color="info" v-on="on">
                  <v-icon small>fas fa-info-circle</v-icon>
                </v-btn>
              </template>
              <v-card 
                width="200px" 
                color="primary" 
                elevation="0"
                class="pa-0 ma-0"
              >
                <v-card-text class="pa-0 ma-0">
                  <v-row style="width: 100%;" no-gutters>
                    <v-col cols="12" align="center" class="pb-0 font-weight-bold">
                      <span>Total Gift: ${{ Intl.NumberFormat("es-AR").format(item.total_gift) }}</span>
                    </v-col>
                    <v-col cols="12" align="center" class="pb-0 pt-0 font-weight-bold">
                      <span>Entregado Gift: ${{ Intl.NumberFormat("es-AR").format(item.monto_entregado_gift) }}</span>
                    </v-col>
                    <v-col cols="12" align="center" class="pt-0 font-weight-bold">
                      <span>Diferencia Gift: ${{ Intl.NumberFormat("es-AR").format(item.dif_gift) }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tooltip>
          </template>
          <!-- Msj que se mostrara si no existen resultados -->
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay objetivos para mostrar.
            </v-alert>
          </template>
          <!-- Fila de Totales -->
          <template slot="body.append">
            <tr class="font-weight-bold">
              <th></th>
              <th></th>
              <th class="text-center">{{ Intl.NumberFormat("es-AR").format(total_dedic_horaria) }}</th>
              <th class="text-right">{{ formatMoneyRound(total_obj_total_vta) }}</th>
              <th class="text-right">{{ formatMoneyRound(total_vta_alcanzada) }}</th>
              <th class="text-right"></th>
              <template v-if="periodo == null || (periodo != null && moment(moment(periodo, 'MM/YYYY').toDate()).year() < 2025)">
                <th class="text-right">{{ formatMoneyRound(total_obj_acc) }}</th>
                <th class="text-right">{{ formatMoneyRound(total_acc_alcanz) }}</th>
                <th class="text-right"></th>
              </template>
              <template v-if="periodo == null || (periodo != null && moment(moment(periodo, 'MM/YYYY').toDate()).year() >= 2025)">
                <th class="text-right"></th>
                <th class="text-right"></th>
                <th class="text-right"></th>
              </template>
              <th class="text-right">{{ formatMoneyRound(total_incentivo) }}</th>
              <th class="text-right">{{ formatMoneyRound(total_penalizado) }}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro.vue';
import SearchDataTable from '../../components/util/SearchDataTable.vue';
import PeriodoPicker from '../../components/util/PeriodoPicker.vue';
import { roundNumber, format_money_round } from '../../util/utils';
import moment from 'moment';
import DownloadDataTable from '../../components/util/DownloadDataTable.vue'

export default {
  data() {
    return {
      panel: 0,
      moment: moment,
      formatMoneyRound: format_money_round,
      selectedSucursal: {},
      sucursales: [],
      selectedLocal: {},
      locales: [],
      localesTodos: [],
      codigo: '',
      nombre: '',
      load: false,
      headers: [
        { text: 'Local', align: 'left', value: 'punto_venta' },
        { text: 'Vendedor', align: 'left', value: 'vendedor' },
        { text: '% Dedic. Horaria', align: 'center', value: 'dedicacion_horaria' },
        { text: 'Obj. Total. Vta.', align: 'right', value: 'obj_solicitado_total_vta', formatter: format_money_round },
        { text: 'Vta. Alcanzada', align: 'right', value: 'objetivo_total_alcanzado', formatter: format_money_round },
        { text: '% Proy. Vta.', align: 'center', value: 'porc_proy_vta' },
        { text: 'Obj. Acc.', align: 'right', value: 'obj_solicitado_acc_piso', formatter: format_money_round },
        { text: 'Acc. Alcanzado', align: 'right', value: 'obj_acc_alcanzado', formatter: format_money_round },
        { text: '% Proy. Acc.', align: 'center', value: 'porc_proy_acc' },
        { text: 'Obj. Pto. Vta.', align: 'right', value: 'obj_local', formatter: format_money_round },
        { text: 'Pto. Vta. Alcanzado', align: 'right', value: 'obj_local_alcanzado', formatter: format_money_round },
        { text: '% Proy. Pto. Vta.', align: 'center', value: 'porc_proy_local' },
        { text: 'Incentivo', align: 'right', value: 'incentivo', formatter: format_money_round },
        { text: 'Penaliz.', align: 'right', value: 'penalizaciones', formatter: format_money_round },
        { text: '', align: 'center', value: 'acciones' }
      ],
      headerss: [
        { text: 'Periodo', align: 'left', value: 'periodo' },
        { text: 'Local', align: 'left', value: 'punto_venta' },
        { text: '% Dedic. Horaria', align: 'center', value: 'dedicacion_horaria' },
        { text: 'Obj. Total. Vta.', align: 'right', value: 'obj_solicitado_total_vta', formatter: format_money_round },
        { text: 'Vta. Alcanzada', align: 'right', value: 'objetivo_total_alcanzado', formatter: format_money_round },
        { text: '% Proy. Vta.', align: 'center', value: 'porc_proy_vta' },
        { text: 'Obj. Acc.', align: 'right', value: 'obj_solicitado_acc_piso', formatter: format_money_round },
        { text: 'Acc. Alcanzado', align: 'right', value: 'obj_acc_alcanzado', formatter: format_money_round },
        { text: '% Proy. Acc.', align: 'center', value: 'porc_proy_acc' },
        { text: 'Obj. Pto. Vta.', align: 'right', value: 'obj_local', formatter: format_money_round },
        { text: 'Pto. Vta. Alcanzado', align: 'right', value: 'obj_local_alcanzado', formatter: format_money_round },
        { text: '% Proy. Pto. Vta.', align: 'center', value: 'porc_proy_local' },
        { text: 'Incentivo', align: 'right', value: 'incentivo', formatter: format_money_round },
        { text: 'Penaliz.', align: 'right', value: 'penalizaciones', formatter: format_money_round },
        { text: '', align: 'center', value: 'acciones' }
      ],
      objetivos: [],
      search: '',
      periodo: null,
      total_dedic_horaria: 0,
      total_obj_total_vta: 0,
      total_vta_alcanzada: 0,
      total_porc_proy_vta: 0,
      total_obj_acc: 0,
      total_acc_alcanz: 0,
      total_porc_proy_acc: 0,
      total_incentivo: 0,
      total_penalizado: 0,
      ver_periodo: 0
    }
  },
  components: {
    BtnFiltro,
    SearchDataTable,
    PeriodoPicker,
    DownloadDataTable
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.load = true
      let localesPeticion = await this.$store.dispatch('objetivos/get_locales')
      this.load = false
      if (localesPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: localesPeticion.message,
          color: 'error',
        })
        return
      }
      this.localesTodos = localesPeticion.data
    },
    getLocales(){
      if (this.selectedSucursal == null || this.selectedSucursal == undefined) return
      this.selectedLocal = {}
      if (Object.keys(this.selectedSucursal).length > 0){
        this.locales = []
        this.locales = this.localesTodos.filter(element => element.sucursal == this.selectedSucursal.id && element.empresa == 2)
      }
    },
    async buscar(){
      this.ver_periodo = 0
      // si no ingresa el periodo ni el vendedor, debe ingresar uno si o si
      if ((this.periodo == null || this.periodo == undefined || this.periodo.toString().length == 0) && (this.codigo == null || this.codigo == undefined || this.codigo.toString().length == 0) &&
      (this.nombre == null || this.nombre == undefined || this.nombre.toString().length == 0)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar el periodo o bien ingresar un vendedor para empezar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.objetivos = []
      let query = {
        sucursal: 0,
        local: 0,
        vendedor_codigo: 0,
        vendedor_nombre: '',
        periodo: ''
      }
      //sucursal
      if (Object.keys(this.selectedSucursal).length > 0) query.sucursal = this.selectedSucursal.id
      // local
      if (Object.keys(this.selectedLocal).length > 0) query.local = this.selectedLocal.id
      // codigo de vendedor
      if (this.codigo != null && this.codigo != undefined && this.codigo.toString().length > 0) query.vendedor_codigo = this.codigo
      // nombre vendedor
      if (this.nombre != null && this.nombre != undefined && this.nombre.toString().length > 0) query.vendedor_nombre = this.nombre
      // periodo
      if (this.periodo != null && this.periodo != undefined && this.periodo.toString().length > 0) query.periodo = this.periodo
      // muestro el periodo?
      if (this.codigo.toString().length > 0 || this.nombre.toString().length > 0) this.ver_periodo = 1
      // consulta
      this.load = true
      let objPeticion = await this.$store.dispatch('objetivos/get_obj_alcanzados', query)
      this.load = false
      if (objPeticion.resultado == 1){
        this.objetivos = objPeticion.objetivos
      }else{
        this.$store.dispatch('show_snackbar', {
          text: objPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiar(){
      this.selectedSucursal = {}
      this.selectedLocal = {}
      this.locales = []
      this.codigo = ''
      this.nombre = ''
      this.periodo = null
    },
    setHeaders () {

      this.objetivos = []

      // limpio todito
      let existe = this.headers.find(hd => hd.value == 'obj_solicitado_acc_piso')
      if (existe) this.headers.splice(this.headers.indexOf(existe), 1)
      existe = this.headers.find(hd => hd.value == 'obj_acc_alcanzado')
      if (existe) this.headers.splice(this.headers.indexOf(existe), 1)
      existe = this.headers.find(hd => hd.value == 'porc_proy_acc')
      if (existe) this.headers.splice(this.headers.indexOf(existe), 1)

      existe = this.headers.find(hd => hd.value == 'obj_local')
      if (existe) this.headers.splice(this.headers.indexOf(existe), 1)
      existe = this.headers.find(hd => hd.value == 'obj_local_alcanzado')
      if (existe) this.headers.splice(this.headers.indexOf(existe), 1)
      existe = this.headers.find(hd => hd.value == 'porc_proy_local')
      if (existe) this.headers.splice(this.headers.indexOf(existe), 1)

      existe = this.headerss.find(hd => hd.value == 'obj_solicitado_acc_piso')
      if (existe) this.headerss.splice(this.headerss.indexOf(existe), 1)
      existe = this.headerss.find(hd => hd.value == 'obj_acc_alcanzado')
      if (existe) this.headerss.splice(this.headerss.indexOf(existe), 1)
      existe = this.headerss.find(hd => hd.value == 'porc_proy_acc')
      if (existe) this.headerss.splice(this.headerss.indexOf(existe), 1)

      existe = this.headerss.find(hd => hd.value == 'obj_local')
      if (existe) this.headerss.splice(this.headerss.indexOf(existe), 1)
      existe = this.headerss.find(hd => hd.value == 'obj_local_alcanzado')
      if (existe) this.headerss.splice(this.headerss.indexOf(existe), 1)
      existe = this.headerss.find(hd => hd.value == 'porc_proy_local')
      if (existe) this.headerss.splice(this.headerss.indexOf(existe), 1)

      if (moment(moment(this.periodo, 'MM/YYYY').toDate()).year() >= 2025) {
        this.headers.splice(6, 0, { text: 'Obj. Pto. Vta.', align: 'right', value: 'obj_local', formatter: format_money_round })
        this.headers.splice(7, 0, { text: 'Pto. Vta. Alcanzado', align: 'right', value: 'obj_local_alcanzado', formatter: format_money_round })
        this.headers.splice(8, 0, { text: '% Proy. Pto. Vta.', align: 'center', value: 'porc_proy_local' })
        this.headerss.splice(6, 0, { text: 'Obj. Pto. Vta.', align: 'right', value: 'obj_local', formatter: format_money_round })
        this.headerss.splice(7, 0, { text: 'Pto. Vta. Alcanzado', align: 'right', value: 'obj_local_alcanzado', formatter: format_money_round })
        this.headerss.splice(8, 0, { text: '% Proy. Pto. Vta.', align: 'center', value: 'porc_proy_local' })
      } else {
        this.headers.splice(6, 0, { text: 'Obj. Acc.', align: 'right', value: 'obj_solicitado_acc_piso', formatter: format_money_round })
        this.headers.splice(7, 0, { text: 'Acc. Alcanzado', align: 'right', value: 'obj_acc_alcanzado', formatter: format_money_round })
        this.headers.splice(8, 0, { text: '% Proy. Acc.', align: 'center', value: 'porc_proy_acc' })
        this.headerss.splice(6, 0, { text: 'Obj. Acc.', align: 'right', value: 'obj_solicitado_acc_piso', formatter: format_money_round })
        this.headerss.splice(7, 0, { text: 'Acc. Alcanzado', align: 'right', value: 'obj_acc_alcanzado', formatter: format_money_round })
        this.headerss.splice(8, 0, { text: '% Proy. Acc.', align: 'center', value: 'porc_proy_acc' })
      }
    }
  },
  watch: {
    objetivos: function(){
      // reseteo los totales
      this.total_dedic_horaria = 0
      this.total_obj_total_vta = 0
      this.total_vta_alcanzada = 0
      this.total_porc_proy_vta = 0
      this.total_obj_acc = 0
      this.total_acc_alcanz = 0
      this.total_porc_proy_acc = 0
      this.total_incentivo = 0
      this.total_penalizado = 0
      if (this.objetivos.length > 0){
        for (let id in this.objetivos){
          this.objetivos[id].autocomision_parlantes = roundNumber(this.objetivos[id].autocomision_parlantes, 2)
          this.objetivos[id].autocomision_seguros = roundNumber(this.objetivos[id].autocomision_seguros, 2)
          this.objetivos[id].dedicacion_horaria = roundNumber(this.objetivos[id].dedicacion_horaria, 2)
          this.objetivos[id].extra_gift_card = roundNumber(this.objetivos[id].extra_gift_card, 2)
          this.objetivos[id].extra_objetivo = roundNumber(this.objetivos[id].extra_objetivo, 2)
          this.objetivos[id].gift_s1 = roundNumber(this.objetivos[id].gift_s1, 2)
          this.objetivos[id].gift_s2 = roundNumber(this.objetivos[id].gift_s2, 2)
          this.objetivos[id].gift_s3 = roundNumber(this.objetivos[id].gift_s3, 2)
          this.objetivos[id].gift_s4 = roundNumber(this.objetivos[id].gift_s4, 2)
          this.objetivos[id].monto_entregado_gift = roundNumber(this.objetivos[id].monto_entregado_gift, 2)
          this.objetivos[id].monto_gift_card = roundNumber(this.objetivos[id].monto_gift_card, 2)
          this.objetivos[id].obj_acc_alcanzado = roundNumber(this.objetivos[id].obj_acc_alcanzado, 2)
          this.objetivos[id].obj_alc_semana1 = roundNumber(this.objetivos[id].obj_alc_semana1, 2)
          this.objetivos[id].obj_alc_semana2 = roundNumber(this.objetivos[id].obj_alc_semana2, 2)
          this.objetivos[id].obj_alc_semana3 = roundNumber(this.objetivos[id].obj_alc_semana3, 2)
          this.objetivos[id].obj_alc_semana4 = roundNumber(this.objetivos[id].obj_alc_semana4, 2)
          this.objetivos[id].obj_cum = roundNumber(this.objetivos[id].obj_cum, 2)
          this.objetivos[id].obj_semana_1 = roundNumber(this.objetivos[id].obj_semana_1, 2)
          this.objetivos[id].obj_semana_2 = roundNumber(this.objetivos[id].obj_semana_2, 2)
          this.objetivos[id].obj_semana_3 = roundNumber(this.objetivos[id].obj_semana_3, 2)
          this.objetivos[id].obj_semana_4 = roundNumber(this.objetivos[id].obj_semana_4, 2)
          this.objetivos[id].obj_solicitado_acc_piso = roundNumber(this.objetivos[id].obj_solicitado_acc_piso, 2)
          this.objetivos[id].obj_solicitado_seguros = roundNumber(this.objetivos[id].obj_solicitado_seguros, 2)
          this.objetivos[id].obj_solicitado_total_vta = roundNumber(this.objetivos[id].obj_solicitado_total_vta, 2)
          this.objetivos[id].objetivo_acc = roundNumber(this.objetivos[id].objetivo_acc, 2)
          this.objetivos[id].objetivo_total_alcanzado = roundNumber(this.objetivos[id].objetivo_total_alcanzado, 2)
          this.objetivos[id].obj_local = roundNumber(this.objetivos[id].obj_local, 2)
          this.objetivos[id].obj_local_alcanzado = roundNumber(this.objetivos[id].obj_local_alcanzado, 2)
          this.objetivos[id].porc_semana1 = roundNumber(this.objetivos[id].porc_semana1, 2)
          this.objetivos[id].porc_semana2 = roundNumber(this.objetivos[id].porc_semana2, 2)
          this.objetivos[id].porc_semana3 = roundNumber(this.objetivos[id].porc_semana3, 2)
          this.objetivos[id].porc_semana4 = roundNumber(this.objetivos[id].porc_semana4, 2)
          // agrego los campos calculados
          this.objetivos[id].porc_proy_vta = roundNumber(roundNumber(((this.objetivos[id].objetivo_total_alcanzado/this.objetivos[id].dias_habiles_actuales)*this.objetivos[id].dias_habiles)/this.objetivos[id].obj_solicitado_total_vta, 6)*100, 2)
          this.objetivos[id].porc_proy_acc = roundNumber(roundNumber(((this.objetivos[id].obj_acc_alcanzado/this.objetivos[id].dias_habiles_actuales)*this.objetivos[id].dias_habiles)/this.objetivos[id].obj_solicitado_acc_piso, 6)*100, 2)
          this.objetivos[id].porc_proy_local = roundNumber(roundNumber(((this.objetivos[id].obj_local_alcanzado/this.objetivos[id].dias_habiles_actuales)*this.objetivos[id].dias_habiles)/this.objetivos[id].obj_local, 6)*100, 2)
          let vta1 = this.objetivos[id].objetivo_total_alcanzado/(this.objetivos[id].obj_solicitado_total_vta > 0 ? this.objetivos[id].obj_solicitado_total_vta : 0.1)
          let acc1 = this.objetivos[id].obj_acc_alcanzado/(this.objetivos[id].obj_solicitado_acc_piso > 0 ? this.objetivos[id].obj_solicitado_acc_piso : 0.1)
          let loc1 = this.objetivos[id].obj_local_alcanzado/(this.objetivos[id].obj_local > 0 ? this.objetivos[id].obj_local : 0.1)
          
          const periodo = moment(this.objetivos[id].periodo).format('MM/YYYY')
          
          if (moment(this.objetivos[id].periodo).year() >= 2025) {
            let monto_vendedor = 0
            let monto_local = 0

            if (periodo == '03/2025') {
              if (roundNumber(vta1, 2) >= 1) {
                monto_vendedor = roundNumber(0.02 * this.objetivos[id].objetivo_total_alcanzado, 2)
              } else if (roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1) {
                monto_vendedor = roundNumber(0.01 * this.objetivos[id].objetivo_total_alcanzado, 2)
              }
            } else {
              if (roundNumber(vta1, 2) >= 0.8) {
                if (roundNumber(vta1, 2) >= 1) {
                  monto_vendedor = roundNumber(0.014 * this.objetivos[id].objetivo_total_alcanzado, 2)
                } else if (roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1) {
                  monto_vendedor = roundNumber(0.007 * this.objetivos[id].objetivo_total_alcanzado, 2)
                }

                if (roundNumber(loc1, 2) >= 1) {
                  monto_local = roundNumber(0.006 * this.objetivos[id].objetivo_total_alcanzado, 2)
                } else if (roundNumber(loc1, 2) >= 0.8 && roundNumber(loc1, 2) < 1) {
                  monto_local = roundNumber(0.003 * this.objetivos[id].objetivo_total_alcanzado, 2)
                }
              }
            }

            this.objetivos[id].incentivo = monto_vendedor + monto_local

          } else if (periodo != '12/2023' && moment(periodo, 'MM/YYYY').toDate() < moment('01/02/2023', 'DD/MM/YYYY').toDate()) {
            if (roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1){
              if (roundNumber(acc1, 2) < 0.8) this.objetivos[id].incentivo = roundNumber(0.005*this.objetivos[id].objetivo_total_alcanzado, 2)
              else this.objetivos[id].incentivo = roundNumber(0.01*this.objetivos[id].objetivo_total_alcanzado, 2)
            }else if (roundNumber(vta1, 2) >= 1){
              if (roundNumber(acc1, 2) < 0.8) this.objetivos[id].incentivo = roundNumber(0.01*this.objetivos[id].objetivo_total_alcanzado, 2)
              else if (roundNumber(acc1, 2) >= 1) this.objetivos[id].incentivo = roundNumber(0.02*this.objetivos[id].objetivo_total_alcanzado, 2)
              else this.objetivos[id].incentivo = roundNumber(0.015*this.objetivos[id].objetivo_total_alcanzado, 2)
            }else{
              if (moment(periodo, "MM/YYYY").toDate() == moment('01/02/2022', "DD/MM/YYYY").toDate()){
                if (roundNumber(acc1, 2) >= 0.8) this.objetivos[id].incentivo = roundNumber(0.01*this.objetivos[id].objetivo_total_alcanzado, 2)
                else this.objetivos[id].incentivo = 0
              }else this.objetivos[id].incentivo = 0
            }
          } else if (periodo == '12/2023') {
            if (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 1) {
              this.objetivos[id].incentivo = roundNumber(0.02 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.7 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 0.7 && roundNumber(acc1, 2) < 1) {
              this.objetivos[id].incentivo = roundNumber(0.01 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0.7 && roundNumber(acc1, 2) < 1) ||
              (roundNumber(vta1, 2) >= 0.7 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 1)) {
              this.objetivos[id].incentivo = roundNumber(0.015 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.7 || roundNumber(acc1, 2) >= 0.7) {
              this.objetivos[id].incentivo = roundNumber(0.005 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else 0
          } else if (periodo == '02/2024') {
            if (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 1) {
              this.objetivos[id].incentivo = roundNumber(0.02 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 0.8 && roundNumber(acc1, 2) < 1) ||
                     (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0 && roundNumber(acc1, 2) < 0.6)  ||
                     (roundNumber(acc1, 2) >= 1 && roundNumber(vta1, 2) >= 0 && roundNumber(vta1, 2) < 0.6)) {
              this.objetivos[id].incentivo = roundNumber(0.01 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(acc1, 2) >= 1 && roundNumber(vta1, 2) >= 0.6 && roundNumber(vta1, 2) <= 0.8)  ||
                     (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0.6 && roundNumber(acc1, 2) <= 0.8)) {
              this.objetivos[id].incentivo = roundNumber(0.0125 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0.8 && roundNumber(acc1, 2) < 1) ||
              (roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 1)) {
              this.objetivos[id].incentivo = roundNumber(0.015 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 0.6 && roundNumber(vta1, 2) < 0.8 && roundNumber(acc1, 2) >= 0.6 && roundNumber(acc1, 2) < 0.8) ||
              (roundNumber(acc1, 2) >= 0.6 && roundNumber(acc1, 2) < 0.8 && roundNumber(vta1, 2) >= 0.6 && roundNumber(vta1, 2) < 0.8)) {
              this.objetivos[id].incentivo = roundNumber(0.0075 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.8 || roundNumber(acc1, 2) >= 0.8 || (roundNumber(vta1, 2) >= 0.6 && roundNumber(acc1, 2) >= 0.6)) {
              this.objetivos[id].incentivo = roundNumber(0.005 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.6 || roundNumber(acc1, 2) >= 0.6) {
              this.objetivos[id].incentivo = roundNumber(0.0025 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else 0
          } else if (periodo == '03/2024') {
            if (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 1) {
              this.objetivos[id].incentivo = roundNumber(0.02 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 0.8 && roundNumber(acc1, 2) < 1) ||
                     (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0 && roundNumber(acc1, 2) < 0.8)  ||
                     (roundNumber(acc1, 2) >= 1 && roundNumber(vta1, 2) >= 0 && roundNumber(vta1, 2) < 0.8))  {
              this.objetivos[id].incentivo = roundNumber(0.01 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0.8 && roundNumber(acc1, 2) < 1) ||
              (roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 1)) {
              this.objetivos[id].incentivo = roundNumber(0.015 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.8 || roundNumber(acc1, 2) >= 0.8) {
              this.objetivos[id].incentivo = roundNumber(0.005 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.6 || roundNumber(acc1, 2) >= 0.6) {
              this.objetivos[id].incentivo = roundNumber(0.0025 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else 0
          } else {
            if (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 1) {
              this.objetivos[id].incentivo = roundNumber(0.02 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 0.8 && roundNumber(acc1, 2) < 1) ||
                     (roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0 && roundNumber(acc1, 2) < 0.8)  ||
                     (roundNumber(acc1, 2) >= 1 && roundNumber(vta1, 2) >= 0 && roundNumber(vta1, 2) < 0.8))  {
              this.objetivos[id].incentivo = roundNumber(0.01 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if ((roundNumber(vta1, 2) >= 1 && roundNumber(acc1, 2) >= 0.8 && roundNumber(acc1, 2) < 1) ||
              (roundNumber(vta1, 2) >= 0.8 && roundNumber(vta1, 2) < 1 && roundNumber(acc1, 2) >= 1)) {
              this.objetivos[id].incentivo = roundNumber(0.015 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else if (roundNumber(vta1, 2) >= 0.8 || roundNumber(acc1, 2) >= 0.8) {
              this.objetivos[id].incentivo = roundNumber(0.005 * this.objetivos[id].objetivo_total_alcanzado, 2)
            }
            else 0
          }
          this.objetivos[id].total_gift = parseInt(this.objetivos[id].incentivo + (this.objetivos[id].autocomision_seguros > 0 ? this.objetivos[id].autocomision_seguros : 0) + this.objetivos[id].penalizaciones)
          this.objetivos[id].dif_gift = roundNumber(this.objetivos[id].total_gift - this.objetivos[id].monto_entregado_gift, 2)
          // acumulo los totales
          this.total_dedic_horaria = this.total_dedic_horaria + this.objetivos[id].dedicacion_horaria
          this.total_obj_total_vta = this.total_obj_total_vta + this.objetivos[id].obj_solicitado_total_vta
          this.total_vta_alcanzada = this.total_vta_alcanzada + this.objetivos[id].objetivo_total_alcanzado
          this.total_porc_proy_vta = this.total_porc_proy_vta + this.objetivos[id].porc_proy_vta
          this.total_obj_acc = this.total_obj_acc + this.objetivos[id].obj_solicitado_acc_piso
          this.total_acc_alcanz = this.total_acc_alcanz + this.objetivos[id].obj_acc_alcanzado
          this.total_porc_proy_acc = this.total_porc_proy_acc + this.objetivos[id].porc_proy_acc
          this.total_incentivo = this.total_incentivo + this.objetivos[id].incentivo
          this.total_penalizado = this.total_penalizado + this.objetivos[id].penalizaciones
        }
        this.total_dedic_horaria = roundNumber(this.total_dedic_horaria, 2)
        this.total_obj_total_vta = roundNumber(this.total_obj_total_vta, 2)
        this.total_vta_alcanzada = roundNumber(this.total_vta_alcanzada, 2)
        this.total_porc_proy_vta = roundNumber(this.total_porc_proy_vta, 2)
        this.total_obj_acc = roundNumber(this.total_obj_acc, 2)
        this.total_acc_alcanz = roundNumber(this.total_acc_alcanz, 2)
        this.total_porc_proy_acc = roundNumber(this.total_porc_proy_acc, 2)
        this.total_incentivo = roundNumber(this.total_incentivo, 2)
        this.total_penalizado = roundNumber(this.total_penalizado, 2)
      }
    },
    selectedSucursal: function(){
      if (this.selectedSucursal == null || this.selectedSucursal == undefined) this.selectedSucursal = {}
    },
    selectedLocal: function(){
      if (this.selectedLocal == null || this.selectedLocal == undefined) this.selectedLocal = {}
    },
    codigo: function(){
      if (this.codigo == null || this.codigo == undefined) this.codigo = ''
      if (this.nombre == null || this.nombre == undefined) this.nombre = ''
      // if (this.codigo.toString().length == 0 && this.nombre.toString().length == 0) this.ver_periodo = 0
      // else this.ver_periodo = 1
    },
    nombre: function(){
      if (this.codigo == null || this.codigo == undefined) this.codigo = ''
      if (this.nombre == null || this.nombre == undefined) this.nombre = ''
      // if (this.codigo.toString().length == 0 && this.nombre.toString().length == 0) this.ver_periodo = 0
      // else this.ver_periodo = 1
    }
  },
}
</script>

<style>

</style>