<template>
  <v-dialog
    v-model="activo"
    max-width="700"
  >
    <v-card
      flat
      :loading="load"
    >
      <v-card-title>
        <div v-if="nueva == 1"><v-icon small class="ma-2">fas fa-plus-circle</v-icon> Nueva Acreditación</div>
        <div v-else><v-icon small class="ma-2">fas fa-edit</v-icon> Acreditación {{ acredit.acreditacion.numero }}</div>
        <v-spacer></v-spacer>
        <v-expand-x-transition>
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-center py-0 my-0"
            hide-details
            dense
            :true-value="1"
            :false-value="0"
            v-if="nueva == 0 && acredit.acreditacion.manual == 1 && permiteGrabar == true && load == false && (permisoModificar == 1 || conciliado == 0)"
          ></v-switch>
        </v-expand-x-transition>
        <v-btn icon @click="activo = false"><v-icon title="Cerrar">fas fa-times</v-icon></v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4 pt-3">
        <!-- Msj que aparecerá si no les permiten modificar -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="conciliado == 1">
          <v-alert
            style="width: 100%;"
            type="warning"
            color="orange"
            border="left"
            dismissible
            text
          >
            <div class="body-2">
              La actual Acreditación se encuentra Conciliada.
            </div>
          </v-alert>
        </v-row>
        <!-- Cliente, Numero, Fecha y Usuario -->
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12" :sm="nueva == 0 ? 2 : 3" :md="nueva == 0 ? 2 : 3" class="py-1">
            <v-text-field
              v-model="clienteCodigo"
              :id="cliCodigo"
              :ref="cliCodigo"
              outlined
              dense
              type="number"
              hide-details
              :readonly="nueva == 0 || p_cliente_codigo != null"
              :filled="nueva == 0 || p_cliente_codigo != null"
              :disabled="p_cliente_codigo != null"
              label="Cód.Cliente."
              @blur="getCliente()"
              @keypress.native.enter="$refs[cliCodigo].blur()"
            >
              <template v-slot:append-outer>
                <v-icon
                  class="mt-1"
                  color="info"
                  title="Buscar cliente"
                  :disabled="nueva == 0 || p_cliente_codigo != null"
                  small
                  @click="buscarCliente()"
                >
                  fas fa-search
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" :sm="nueva == 0 ? 5 : 6" :md="nueva == 0 ? 5 : 6" class="py-1">
            <v-text-field
              v-model="clienteNombre"
              outlined
              dense
              type="text"
              hide-details
              readonly
              label="Nom. Cliente"
              ref="cliNombre"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="editar == 1 ? 2 : 3" :md="editar == 1 ? 2 : 3" class="py-1" v-if="nueva == 0">
            <v-text-field
              v-model="numero"
              outlined
              dense
              type="number"
              hide-details
              :readonly="nueva == 0"
              :filled="nueva == 0"
              label="Numero"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="nueva == 0 && editar == 0">
            <v-text-field
              v-model="fecha"
              outlined
              dense
              type="text"
              hide-details
              label="Fecha Cupón"
              :readonly="nueva == 0"
              :filled="nueva == 0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1" v-if="(nueva == 1 || editar == 1) && clienteValido == true">
            <FechaPicker 
              v-model="fechaPick"
              label="Fecha Cupón"
              hideDetails
              :disabled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
            />
          </v-col>
        </v-row>
        <!-- Origen, Factura o Nro de Seq Term, Cupon o Num. Term, Lote o Fecha Pago, Cuotas o Estado -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12" sm="3" md="3" class="py-1" v-if="nueva == 0 && editar == 0">
            <v-autocomplete
              v-model="selectedOrigen"
              item-text="origen_nombre"
              :items="origenes"
              hide-details
              outlined
              dense
              return-object
              label="Origen"
              disabled
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1" v-if="(nueva == 1 && clienteValido == true) || (editar == 1 && clienteValido == true)">
            <v-autocomplete
              v-model="selectedOrigen"
              item-text="origen_nombre"
              :items="origenesNuevo"
              hide-details
              outlined
              dense
              return-object
              label="Origen"
              @change="resetCampos()"
              :disabled="nueva == 0"
            ></v-autocomplete>
          </v-col>
          <!-- Factura o Nro de Seq Term -->
          <v-col cols="12" sm="3" md="3" class="py-1" v-if="(nueva == 0 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0)) || (nueva == 1 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) && clienteValido == true)">
            <v-text-field
              v-model="factura"
              outlined
              dense
              type="text"
              hide-details
              :readonly="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :filled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              label="Cod. Autorización"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="nueva == 0 ? 2 : 3" :md="nueva == 0 ? 2 : 3" class="py-1" v-if="(nueva == 0 && selectedOrigen.circuito == 1) || (nueva == 1 && selectedOrigen.circuito == 1 && clienteValido == true)">
            <v-text-field
              v-model="nroSeqTerm"
              outlined
              dense
              type="text"
              hide-details
              :readonly="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :filled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              label="Nro. Seq. Term."
            ></v-text-field>
          </v-col>
          <!-- Cupon o Num. Term -->
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="(nueva == 0 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0)) || (nueva == 1 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) && clienteValido == true)">
            <v-text-field
              v-model="cupon"
              outlined
              dense
              type="number"
              hide-details
              :readonly="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :filled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              label="Cupón"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="nueva == 0 ? 2 : 3" :md="nueva == 0 ? 2 : 3" class="py-1" v-if="(nueva == 0 && selectedOrigen.circuito == 1) || (nueva == 1 && selectedOrigen.circuito == 1 && clienteValido == true)">
            <v-text-field
              v-model="nroTerm"
              outlined
              dense
              type="text"
              hide-details
              :readonly="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :filled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              label="Nro. Term."
            ></v-text-field>
          </v-col>
          <!-- Lote o Fecha Pago -->
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="(nueva == 0 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0)) || (nueva == 1 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) && clienteValido == true)">
            <v-text-field
              v-model="lote"
              outlined
              dense
              type="number"
              hide-details
              :readonly="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :filled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              label="Lote"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="selectedOrigen.circuito == 1 && nueva == 0">
            <v-text-field
              v-model="fechaPago"
              outlined
              dense
              type="text"
              hide-details
              readonly
              filled
              label="Fecha Pago"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="nueva == 0 ? 2 : 3" :md="nueva == 0 ? 2 : 3" class="py-1" v-if="selectedOrigen.circuito == 1 && nueva == 1 && clienteValido == true">
            <FechaPicker 
              v-model="fechaPago"
              label="Fecha Pago"
              hideDetails
              :disabled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :fecha_maxima="moment(new Date()).format('DD/MM/YYYY')"
            />
          </v-col>
          <!-- Cuotas o Estado -->
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="(nueva == 0 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0)) || (nueva == 1 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) && clienteValido == true)">
            <v-text-field
              v-model="cuotas"
              :id="cuotaRef"
              :ref="cuotaRef"
              outlined
              dense
              type="number"
              hide-details
              :readonly="nueva == 0"
              :filled="nueva == 0"
              label="Cuotas"
              @blur="getInteresCobro()"
              @keypress.native.enter="$refs[cuotaRef].blur()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="nueva == 0 && selectedOrigen.circuito == 1">
            <v-autocomplete
              v-model="selectedEstado"
              item-text="estado_nombre"
              :items="estados"
              hide-details
              outlined
              dense
              return-object
              label="Estado"
              :disabled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              :filled="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- Tarjeta Numero y Tarjeta Marca -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="(nueva == 0 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0)) || (nueva == 1 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) && clienteValido == true)" class="pt-2">
          <v-col cols="12" sm="6" md="6" class="py-1">
            <the-mask
              v-model="tarNumero"
              :mask="['####-####-####-####','####-####-####-###', '####-####-#####-####','####-####-####-####-###' ]"
              :class="$vuetify.theme.dark ? 'white--text tarjetaCss' : 'black--text tarjetaCss'"
              :readonly="(nueva == 0 && editar == 0) || (conciliado == 1 && permisoModificar == 0)"
              placeholder="Tarjeta Número"
            ></the-mask>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-1" v-if="nueva == 1 || editar == 1">
            <v-autocomplete
              v-model="selectedTarjeta"
              item-text="tarjeta_nombre"
              :items="tarjetas"
              hide-details
              outlined
              dense
              return-object
              label="Marca"
              :disabled="nueva == 0"
              :filled="nueva == 0"
              @change="getInteresCobro()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="py-1" v-if="nueva == 0 && editar == 0">
            <v-text-field
              v-model="tarjetaMarca"
              outlined
              dense
              type="text"
              hide-details
              label="Marca"
              :readonly="nueva == 0"
              :filled="nueva == 0"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Importe, Saldo Virtual, % Retenciones y Consignación -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2" v-if="nueva == 0 || (nueva == 1 && clienteValido == true)">
          <v-col cols="12" :sm="(selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) ? 5 : 6" :md="(selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) ? 5 : 6" class="py-1">
            <TextFieldMoney
              v-model="importe"
              :id="impRef"
              :ref="impRef"
              label="Importe"
              @blur="calcularAcreditacion()"
              @keypress.native.enter="$refs[impRef].blur()"
              v-bind:properties="{
                prefix: '$',
                'hide-details': true,
                'validate-on-blur': true,
                'readonly': nueva == 0,
                'filled': nueva == 0,
              }"
            />
          </v-col>
          <v-col cols="12" sm="2" md="2" class="py-1" v-if="(nueva == 0 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0)) || (nueva == 1 && (selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) && clienteValido == true)">
            <v-text-field
              v-model="retencionesPorcentaje"
              :id="retRef"
              :ref="retRef"
              outlined
              dense
              type="number"
              hide-details
              readonly
              filled
              label="Intereses"
              suffix="%"
              @blur="calcularAcreditacion()"
              @keypress.native.enter="$refs[retRef].blur()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="(selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) ? 5 : 6" :md="(selectedOrigen.circuito == 2 || Object.keys(selectedOrigen).length == 0) ? 5 : 6" class="py-1">
            <v-text-field
              v-model="consignacion"
              outlined
              dense
              type="number"
              hide-details
              readonly
              filled
              label="Acreditado"
              prefix="$"
            ></v-text-field>
          </v-col>
          <!-- seccion para subir cupon -->
          <v-col cols="12" v-if="nueva == 1 || (editar == 1 && (conciliado == 0 || permisoModificar == 1))" class="pb-0">
            <v-form ref="formImg">
              <v-file-input
                v-model="file"
                label="Cupón"
                accept="image/jpeg, image/png, image/webp, image/heic, image/heif"
                prepend-icon="fas fa-camera"
                :truncate-length="100"
                :rules="rules"
                outlined
                dense
              ></v-file-input>
              <v-alert
                v-if="archivo != null && file != null"
                border="left"
                type="info"
                text
                dense
              >
                La acreditación ya cuenta con una imagen de un cupón. Si sube otra, la anterior será reemplazada.
              </v-alert>
            </v-form>
          </v-col>
          <!-- visor del cupon -->
          <v-col cols="12" v-if="archivo != null && file == null" class="d-flex justify-center pb-0">
            <v-zoomer ref="zoomer" pivot="cursor" :mouseWheelToZoom="false">
              <img
                style="object-fit: contain; width: 100%; height: 100%;"
                :src="`data:image/jpeg;base64,${archivo}`"
              >
            </v-zoomer>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- Botones -->
      <v-card-actions class="d-flex justify-end mx-2">
        <v-btn color="warning" @click="activo = false" class="ma-2" v-if="load == false">Volver</v-btn>
        <BtnConfirmar 
          texto="¿Está seguro de grabar la Acreditación ingresada?"
          @action="grabarAcreditacion()"
          v-if="nueva == 1 && clienteValido == true && permiteGrabar == true"
        />
        <BtnConfirmar 
          texto="¿Está seguro de modificar la Acreditación?"
          nombre="Modificar"
          icono="fas fa-edit"
          @action="modificarAcreditacion()"
          v-if="editar == 1 && clienteValido == true && permiteGrabar == true && acredit.acreditacion.manual == 1"
        />
      </v-card-actions>
    </v-card>
    <!-- Modal Buscar Clientes Mayoristas -->
    <BuscarClienteMayorista 
      :datos="dialogClientes"
      @setearCliente="setCliente"
      @setearBuscarClienteM="setBuscarClienteM"
    />
  </v-dialog>
</template>

<script>
import FechaPicker from '../util/FechaPicker.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import BuscarClienteMayorista from '../../components/generales/clientes/BuscarClienteMayorista'
import moment from 'moment'
import { convertDecimals, roundNumber, tipos_archivos, getBase64, cadenaVacia } from '../../util/utils'
import FileDropzone from '../../components/util/FileDropzone'
import TextFieldMoney from '../util/TextFieldMoney.vue'
import heic2any from 'heic2any'

export default {
  props: {
    datos: {
      type: Object,
      default: {}
    },
    p_cliente_codigo: {
      type: Number,
      default: null
    }
  },
  components: {
    FechaPicker,
    BtnConfirmar,
    BuscarClienteMayorista,
    FileDropzone,
    TextFieldMoney
  },
  computed: {
    activo: {
      get() {
        return this.datos.activo
      },
      set(value) {
        this.$emit('setearModalVerAcred', value)
      }
    },
    origenes: {
      get(){
        return this.datos.origenes
      }
    },
    estados: {
      get(){
        return this.datos.estados
      }
    },
    tarjetas: {
      get(){
        return this.datos.tarjetas
      }
    },
    acredit: {
      get(){
        return this.datos
      }
    },
    ejecutivoCodigo: {
      get(){
        return this.datos.ejecutivo_codigo
      }
    }
  },
  data() {
    return {
      moment: moment,
      clienteCodigo: '',
      clienteNombre: '',
      numero: '',
      fecha: '',
      fechaPick: moment(new Date()).format('DD/MM/YYYY'),
      usuario: '',
      factura: '',
      nroSeqTerm: '',
      selectedOrigen: {},
      cupon: '',
      nroTerm: '',
      lote: '',
      fechaPago: '',
      cuotas: '',
      selectedEstado: {},
      tarNumero: '',
      selectedTarjeta: {},
      tarjetaMarca: '',
      importe: 0,
      retencionesPorcentaje: 0,
      consignacion: 0,
      clienteValido: false,
      cliCodigo: 'cliCodigo',
      origenesNuevo: [],
      cuotaRef: 'cuotaRef',
      permiteGrabar: true,
      impRef: 'impRef',
      retRef: 'retRef',
      dialogClientes: {
        ruta: 'clientes/getClienteMayorista',
        activo: false,
        ejecutivo_codigo: ''
      },
      archivo_cupon: null,
      extension: null,
      archivo: null,
      file: null,
      rules: [
        file => {
          if (file) {
            const tipos_permitidos = ['.png', '.jpg', '.webp','.heic','.heif' ];
            const max_size = 3 * 1024 * 1024; // Tamaño máximo en bytes

            let tipo = file.type

            // Detectar si el archivo es HEIC/HEIF y asignar tipo correctamente
            if (!tipo && file.name.toLowerCase().endsWith('.heic')) {
              file = new File([file], file.name, { type: "image/heic" });
              tipo = file.type;
            }
            if (!tipo && file.name.toLowerCase().endsWith('.heif')) {
              file = new File([file], file.name, { type: "image/heif" });
              tipo = file.type;
            }

            // Buscamos el tipo de archivo en el array tipos_archivos
            const tipo_archivo = tipos_archivos.find(a => a.mime === file.type);

            if (tipo_archivo) {
              // Verifica que el tipo de archivo esté permitido
              if (tipos_permitidos.includes(tipo_archivo.extension)) {
                // Valida el tamaño del archivo
                if (file.size <= max_size) {
                  this.$store.state.loading = true
                  this.extension = file.name.substring(file.name.lastIndexOf('.'), file.name.length)
                  if(this.extension != '.webp'){
                    if(!this.$device.ios){
                      this.$store.state.loading = true
                      this.mostrarMensaje       = true;
                    }
                    const reader = new FileReader();
                    reader.onload = async (e) => {
                      const img = new Image();

                      if (tipo === "image/heic" || tipo === "image/heif") {
                        this.$store.state.loading = true
                        this.mostrarMensaje       = true;
                        try {
                          const converted = await heic2any({
                            blob: file,
                            toType: "image/jpeg",
                          });
                          img.src = URL.createObjectURL(converted);
                        }catch (error) {
                          return false
                        }finally{
                          this.$store.state.loading = false
                          this.mostrarMensaje       = false;
                        }
                      }else img.src = e.target.result;

                      img.onload = () => {
                        const MAX_WIDTH = 1024; // Ancho máximo para procesar
                        const MAX_HEIGHT = 1024;

                        let width = img.width;
                        let height = img.height;

                        // Calcular las dimensiones manteniendo la relación de aspecto
                        if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                          const aspectRatio = width / height;
                          if (width > height) {
                            width = MAX_WIDTH;
                            height = MAX_WIDTH / aspectRatio;
                          } else {
                            height = MAX_HEIGHT;
                            width = MAX_HEIGHT * aspectRatio;
                          }
                        }
                        const canvas  = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.width  = width;
                        canvas.height = height;
                        context.drawImage(img, 0, 0, width, height);
                        canvas.toBlob(
                          async (blob) => {
                            if (blob) {
                              const webpFile = new File([blob], `${file.name.substring(0, file.name.lastIndexOf('.'))}.webp`, {
                                type: "image/webp",
                              });
                              this.file = webpFile; // Agrega el archivo convertido
                            }

                            this.$store.state.loading = false;
                            this.mostrarMensaje = false;
                          },
                          "image/webp",
                          0.8 // Calidad del WebP (0.8 es buena calidad)
                        );
                      };
                      img.onerror = () => {
                        this.$store.state.loading = false
                        this.mostrarMensaje       = false;
                        this.extension = null
                        return 'Error al cargar la imagen: ' + file.name;
                      };
                    };
                    reader.readAsDataURL(file);
                    return true
                  }else{
                    this.$store.state.loading = false
                    return true
                  }
                } else {
                  this.$store.state.loading = false
                  // excedio el peso
                  this.extension = null
                  return `El archivo no debe pesar más de ${max_size}MB`
                }
              } else {
                // subio un archivo con una extension no valida
                this.extension = null
                return 'Tipo de archivo no válido'
              }
            } else {
              // si no lo encuentra es porque subio un tipo de archivo que no esta registrado en el array tipos_archivos
              this.extension = null
              return 'Tipo de archivo no válido para el sistema'
            }
          } else {
            this.extension = null
            if (this.editar == 1 && this.archivo != null) {
              return true
            }
            else {
              return 'Debe subir una foto del cupón'
            }             
          }
        }
      ],
      nueva: 0,
      editar: 0,
      load: false,
      conciliado: 0,
      imputado: 0,
      permisoModificar: 0
    }
  },
  methods: {
    async initForm(){
      // ver o modificar una acreditacion
      this.nueva = cadenaVacia(this.acredit.acreditacion.numero) ? 1 : 0
      if (this.nueva == 0){
        this.clienteCodigo = this.acredit.acreditacion.vendedor_codigo
        this.clienteNombre = this.acredit.acreditacion.vendedor_nombre
        this.numero = this.acredit.acreditacion.numero
        this.fecha = moment(this.acredit.acreditacion.fecha_pago).format('DD/MM/YYYY')
        this.fechaPick = moment(this.acredit.acreditacion.fecha_pago).format('DD/MM/YYYY')
        this.usuario = this.acredit.acreditacion.usuario_grabacion
        this.archivo_cupon = this.acredit.acreditacion.archivo_cupon
        this.archivo = this.acredit.acreditacion.archivo
        // origen
        let unOrigen = this.origenes.filter(element => element.origen_id == this.acredit.acreditacion.entidad_deposito_id)
        if (unOrigen.length > 0) this.selectedOrigen = unOrigen[0]
        // circuito?
        if (this.acredit.acreditacion.circuito == 1){
          // nro seq term
          this.nroSeqTerm = this.acredit.acreditacion.numero_seq_term
          // nro term
          this.nroTerm = this.acredit.acreditacion.term_id
          // fecha pago
          this.fechaPago = moment(this.acredit.acreditacion.fecha_pago).format('DD/MM/YYYY')
          // estado
          let unEstado = this.estados.filter(element => element.estado_id == this.acredit.acreditacion.estado_id)
          if (unEstado.length > 0) this.selectedEstado = unEstado[0]
        }else{
          // factura
          this.factura = this.acredit.acreditacion.codigo_autorizacion
          // cupon
          this.cupon = this.acredit.acreditacion.cupon
          // lote
          this.lote = this.acredit.acreditacion.lote
          // cuotas
          this.cuotas = this.acredit.acreditacion.cuotas
          // tarjeta numero
          this.tarNumero = this.acredit.acreditacion.tarjeta_num
          // tarjeta marca
          this.tarjetaMarca = this.acredit.acreditacion.tarjeta
        }
        // importe
        this.importe = roundNumber(this.acredit.acreditacion.importe, 2)
        // % intereses
        this.retencionesPorcentaje = roundNumber(this.acredit.acreditacion.porc_retencion, 2)
        // total acreditado
        this.consignacion = roundNumber(this.acredit.acreditacion.total_acreditado, 2)
        // 16-07-2024 por MMURILLO, controlo si la acreditación se encuentra conciliado y/o imputada
        this.load = true
        let estadoPeticion = await this.$store.dispatch('acreditaciones/getAcreditacionData', {numero: this.numero})
        this.load = false
        if (estadoPeticion.resultado == 0){
          this.permiteGrabar = false
          this.$store.dispatch('show_snackbar', {
            text: estadoPeticion.msj,
            color: 'error',
          })
          return
        }
        if (estadoPeticion.datos.length == 0){
          this.permiteGrabar = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se encontraron datos para la acreditación seleccionada.',
            color: 'info',
          })
          return
        }
        this.conciliado = estadoPeticion.datos[0].conciliado
        this.imputado = estadoPeticion.datos[0].imputado
        this.permisoModificar = estadoPeticion.permiso
      }
      if (this.p_cliente_codigo) {
        this.clienteCodigo = await JSON.parse(JSON.stringify(this.p_cliente_codigo))
        this.getCliente()
      }
    },
    verificarObligatirios(){
      if (this.clienteCodigo == null || this.clienteCodigo == undefined || this.clienteCodigo.toString().length == 0) return {valido: false, msj: 'Debe Ingresar el Código del Cliente.'}
      if (Object.keys(this.selectedOrigen).length == 0) return {valido: false, msj: 'Debe Ingresar el Origen de la Acreditación'}
      if (this.selectedOrigen.circuito == 1){
        if (this.nroSeqTerm == null || this.nroSeqTerm == undefined || this.nroSeqTerm.toString().length == 0) return {valido: false, msj: 'Debe Ingresar el Nro. de Seq. de la Acreditación.'}
        if (this.nroTerm == null || this.nroTerm == undefined || this.nroTerm.toString().length == 0) return {valido: false, msj: 'Debe Ingresar el Nro. de Terminal de la Acreditación.'}
        if (this.fechaPago == null || this.fechaPago == undefined || this.fechaPago.toString().length == 0) return {valido: false, msj: 'Debe Ingresar la Fecha de Pago de la Acreditación.'}
      }else{
        if (this.factura == null || this.factura == undefined || this.factura.toString().length == 0) return {valido: false, msj: 'Debe Ingresar la Factura de la Acreditación.'}
        if (this.cupon == null || this.cupon == undefined || this.cupon.toString().length == 0) return {valido: false, msj: 'Debe Ingresar el Cupón de la Acreditación.'}
        if (this.lote == null || this.lote == undefined || this.lote.toString().length == 0) return {valido: false, msj: 'Debe Ingresar el Lote de la Acreditación.'}
        if (this.cuotas == null || this.cuotas == undefined || this.cuotas.toString().length == 0) return {valido: false, msj: 'Debe Ingresar las Cuotas de la Acreditación.'}
        if (this.tarNumero == null || this.tarNumero == undefined || this.tarNumero.toString().length == 0) return {valido: false, msj: 'Debe Ingresar el Nro. de Tarjeta de la Acreditación.'}
        if (Object.keys(this.selectedTarjeta).length == 0) return {valido: false, msj: 'Debe Ingresar la Tarjeta de la Acreditación'}
        if (cadenaVacia(this.retencionesPorcentaje)) return {valido: false, msj: 'No calculó el % de Interés para la Acreditación.'}
      }
      if (!this.importe || this.importe == 0/*== null || this.importe == undefined || this.importe.toString().length == 0*/) return {valido: false, msj: 'Debe Ingresar el Importe de la Acreditación.'}
      if (this.consignacion == null || this.consignacion == undefined || this.consignacion.toString().length == 0) return {valido: false, msj: 'No se Calculó el Total a Acreditar.'}
      return {valido: true, msj: 'OK'}
    },
    async grabarAcreditacion(){
      // validacion de la foto del cupon
      if (!this.$refs.formImg.validate()) return

      let valido = this.verificarObligatirios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      let body = {
        circuito: this.selectedOrigen.circuito,
        cliente_codigo: this.clienteCodigo,
        cliente_nombre: this.clienteNombre,
        numero: this.numero,
        fecha: this.fecha,
        fecha_pick: this.fechaPick,
        usuario: this.usuario,
        factura: this.factura,
        nro_seq_term: this.nroSeqTerm,
        origen_id: this.selectedOrigen.origen_id,
        cupon: this.cupon,
        nro_term: this.nroTerm,
        lote: this.lote,
        fecha_pago: this.fechaPago,
        cuotas: this.cuotas,
        tarjeta_codigo: this.selectedTarjeta.tarjeta_codigo,
        tarjeta_numero: this.tarNumero,
        tarjeta_marca: this.selectedTarjeta.tarjeta_nombre,
        importe: roundNumber(this.importe, 2),
        porc_retencion: roundNumber(this.retencionesPorcentaje, 2),
        total_acreditacion: roundNumber(this.consignacion, 2),
        extension: this.extension,
        empresa_codigo: 2
      }
      let grabarPeticion = await this.$store.dispatch('acreditaciones/grabarAcreditacion', body)
      this.$store.state.loading = false
      if (grabarPeticion.resultado == 1){
        // subo la imagen al 40
        await this.subir_cupon(grabarPeticion.id + this.extension)

        this.$store.dispatch('show_snackbar', {
          text: 'Acreditación grabada con éxito',
          color: 'success',
        })
        this.activo = false
        this.$emit('recalcular_saldos')
      }else{
        this.$store.dispatch('show_snackbar', {
          text: grabarPeticion.msj,
          color: 'error',
        })
      }
    },
    limpiarForm(){
        this.clienteCodigo = ''
        this.clienteNombre = ''
        this.numero = ''
        this.fecha = ''
        this.fechaPick = moment(new Date()).format('DD/MM/YYYY')
        this.usuario = ''
        this.factura = ''
        this.nroSeqTerm = ''
        this.selectedOrigen = {}
        this.cupon = ''
        this.nroTerm = ''
        this.lote = ''
        this.fechaPago = ''
        this.cuotas = ''
        this.selectedEstado = {}
        this.tarNumero = ''
        this.selectedTarjeta = {}
        this.tarjetaMarca = ''
        this.importe = 0
        this.saldoVirtual = ''
        this.retencionesPorcentaje = ''
        this.consignacion = ''
        this.origenesNuevo = []
        this.clienteValido = false
        this.archivo_cupon = null
        this.extension = null
        this.archivo = null
        this.file = null
        this.nueva = 0
        this.editar = 0
        this.conciliado = 0
        this.imputado = 0
        this.permisoModificar = 0
        this.permiteGrabar = true
    },
    async getCliente(){
      if (this.nueva == 0 && this.editar == 1) return
      if (this.clienteCodigo != null && this.clienteCodigo != undefined && this.clienteCodigo.toString().length > 0 && (this.nueva == 1 || this.editar == 1)){
        this.$store.state.loading = true
        let clientePeticion = await this.$store.dispatch('clientes/getClienteMayorista', {
          codigo: this.clienteCodigo,
          ejecutivo_codigo: this.ejecutivoCodigo,
          nombre: '',
          nroDocumento: 0
        })
        this.$store.state.loading = false
        if (clientePeticion.resultado == 1){
          if (clientePeticion.clientes.length > 0){
            // lo encontre, habilito para que pueda continuar con la carga
            this.clienteNombre = clientePeticion.clientes[0].vendedor_nombre
            this.usuario = this.$store.state.username
            this.getOrigenes(1)
            this.clienteValido = true
          }else{
            this.$store.dispatch('show_snackbar', {
              text: 'No se encontraron Acreditaciones para el Cliente ingresado o bien no está habilitado a ver sus Acreditaciones.',
              color: 'info',
            })
            this.clienteCodigo = ''
            this.clienteNombre = ''
          }
        }else{
          this.$store.dispatch('show_snackbar', {
            text: clientePeticion.msj,
            color: 'error',
          })
        }
        // muevo el foco al nombre del cliente
        this.$refs.cliNombre.focus()
      }
    },
    resetCampos(){
      if (this.nueva == 1){
        this.factura = ''
        this.nroSeqTerm = ''
        this.cupon = ''
        this.nroTerm = ''
        this.lote = ''
        this.fechaPago = ''
        this.cuotas = ''
        this.selectedEstado = {}
        this.tarNumero = ''
        this.selectedTarjeta = {}
        this.tarjetaMarca = ''
        this.importe = 0
        this.saldoVirtual = ''
        this.retencionesPorcentaje = ''
        this.consignacion = ''
        this.archivo_cupon = null
        this.extension = null
        this.archivo = null
        this.file = null
      }
    },
    async getOrigenes(opcion){
      this.selectedOrigen = {}
      this.$store.state.loading = true
      let origenesPeticion = await this.$store.dispatch('acreditaciones/getOrigenesXCliente', {cliente_codigo: this.clienteCodigo})
      this.$store.state.loading = false
      if (origenesPeticion.resultado == 1){
        this.origenesNuevo = origenesPeticion.origenes
        if (opcion == 2){
          let unOrigen = this.origenesNuevo.filter(element => element.origen_id == this.acredit.acreditacion.entidad_deposito_id)
          if (unOrigen.length > 0) this.selectedOrigen = unOrigen[0]
        }
      }else{
        this.$store.dispatch('show_snackbar', {
          text: origenesPeticion.msj,
          color: 'error',
        })
      }
    },
    async getInteresCobro(){
      if (this.nueva == 0) return
      if(Object.keys(this.selectedOrigen).length > 0){
        if (Object.keys(this.selectedTarjeta).length > 0 && this.cuotas/* != null && this.cuotas != undefined && this.cuotas.toString().length > 0*/){
          this.$store.state.loading = true
          let interesPeticion = await this.$store.dispatch('acreditaciones/getInteresCobro', {
            tarjeta_codigo: this.selectedTarjeta.tarjeta_codigo, 
            origen_codigo: this.selectedOrigen.origen_id, 
            cuotas: this.cuotas,
            empresa_codigo: 2
          })
          this.$store.state.loading = false
          if (interesPeticion.resultado == 1){
            this.permiteGrabar = true
            if(!interesPeticion.porcentaje && interesPeticion.porcentaje != 0){
              return this.$store.dispatch('show_snackbar', { text: "No existe una financiacion disponible para los datos ingresados.", color: 'info' })
            } 
            this.retencionesPorcentaje = roundNumber(interesPeticion.porcentaje, 2)
            this.calcularAcreditacion()
          }else{
            this.$store.dispatch('show_snackbar', {
              text: interesPeticion.msj,
              color: 'error',
            })
          }
        }
      }else {
        if(Object.keys(this.selectedTarjeta).length > 0 && this.cuotas){
          return this.$store.dispatch('show_snackbar', { text: "Se requiere seleccionar un Origen", color: 'info' })
        }
      }
    },
    calcularAcreditacion(){
      if ((this.nueva == 1 || this.editar == 1) && roundNumber(this.importe, 2) > 0 && !cadenaVacia(this.retencionesPorcentaje) && Object.keys(this.selectedOrigen).length > 0 && this.selectedOrigen.circuito == 2){
        this.consignacion = 0
        this.consignacion = roundNumber(roundNumber(this.importe, 2) / (1+(roundNumber(this.retencionesPorcentaje, 2)/100)), 2)
      }
      if ((this.nueva == 1 || this.editar == 1) && this.importe &&
      Object.keys(this.selectedOrigen).length > 0 && this.selectedOrigen.circuito == 1){
        // en el caso de pago facil por ejemplo (circuito 1) no se tiene en cuenta retenciones
        this.consignacion = roundNumber(this.importe, 2)
      }
    },
    async modificarAcreditacion(){
      // validacion de la foto del cupon
      if (!this.$refs.formImg.validate()) return

      let valido = this.verificarObligatirios()
      if (valido.valido == false){
        this.$store.dispatch('show_snackbar', {
          text: valido.msj,
          color: 'warning',
        })
        return
      }
      let body = {
        circuito: this.selectedOrigen.circuito,
        cliente_codigo: this.clienteCodigo,
        cliente_nombre: this.clienteNombre,
        numero: this.numero,
        fecha: this.fecha,
        fecha_pick: this.fechaPick,
        usuario: this.usuario,
        factura: this.factura,
        nro_seq_term: this.nroSeqTerm,
        origen_id: this.selectedOrigen.origen_id,
        cupon: this.cupon,
        nro_term: this.nroTerm,
        lote: this.lote,
        fecha_pago: this.fechaPago,
        cuotas: this.cuotas,
        tarjeta_codigo: this.selectedTarjeta.tarjeta_codigo,
        tarjeta_numero: this.tarNumero,
        tarjeta_marca: this.selectedTarjeta.tarjeta_nombre,
        importe: roundNumber(this.importe, 2),
        porc_retencion: roundNumber(this.retencionesPorcentaje, 2),
        total_acreditacion: roundNumber(this.consignacion, 2),
        archivo: this.extension ? this.numero + this.extension : this.archivo_cupon,
        empresa_codigo: 2
      }
      this.$store.state.loading = true
      let modificaPeticion = await this.$store.dispatch('acreditaciones/editAcreditacion', body)
      this.$store.state.loading = false
      if (modificaPeticion.resultado == 1){
        // subo la imagen al 40 solo si la cambio
        if (this.extension) await this.subir_cupon(this.numero + this.extension)

        this.$store.dispatch('show_snackbar', {
          text: 'La Acreditación fué modificada con éxito.',
          color: 'success',
        })
        setTimeout(() => [location.reload()], 3000)
      }else{
        this.$store.dispatch('show_snackbar', {
          text: modificaPeticion.msj,
          color: 'error',
        })
      }
    },
    buscarCliente(){
      // asigno el ejecutivo
      this.dialogClientes.ejecutivo_codigo = this.ejecutivoCodigo
      // abro el modal
      this.dialogClientes.activo = true
    },
    setBuscarClienteM(value){
      this.dialogClientes.activo = value
      if (this.dialogClientes.activo == false) this.dialogClientes.ejecutivo_codigo = ''
    },
    setCliente(item){
      this.clienteCodigo = item.vendedor_codigo
      this.clienteNombre = item.vendedor_nombre
      this.usuario = this.$store.state.username
      this.getOrigenes(1)
      this.clienteValido = true
    },
    async subir_cupon (nombre) {
      this.$store.state.loading = true
      let base46 = ''
      await getBase64(this.file).then(data => {
        base46 = data.split(',')[1]
      })

      await this.$store.dispatch('upload_file', {
        file: base46,
        name: nombre,
        path: '/indirecta_acre',
        raiz: 1,
        tabla: 1,
        id: 1
      })
      this.$store.state.loading = false
    }
  },
  watch: {
    activo: function(){
      if (this.activo == false) this.limpiarForm()
      else this.initForm()
    },
    editar: function(){
      if (this.editar == 1){
        this.clienteValido = true
        // origen
        this.getOrigenes(2)
        // tarjeta
        let unaTarjeta = this.tarjetas.filter(element => element.tarjeta_nombre.toUpperCase().replace(/ /g,'') == this.acredit.acreditacion.tarjeta.toUpperCase().replace(/ /g,''))
        if (unaTarjeta.length > 0) this.selectedTarjeta = unaTarjeta[0]
        else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo determinar la marca de la tarjeta utilizada.',
            color: 'info',
          })
        }
      }
    }
  },
}
</script>

<style scoped>
  .tarjetaCss {
    width: 100%;
    height: 32px;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    padding: 5px 10px;
    background: none;
    font-family: Roboto,sans-serif;
    font-size: 15px;
  }
</style>